import React, { Component } from 'react'
import { Fade } from 'react-reveal'
import EditorToolbar, { formats, modules } from '../EditorToolbar/EditorToolbar'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';

import { icons } from '../../assets/icons'

import './styles.css'

export default class EditTextModal extends Component {

    componentDidMount = () => {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClickOutside)
    }

    handleClickOutside = (e) => {
        if (e.target.classList.contains('editText-modal-overlay')) {
            this.props.close();
        }
    }

    render() {
        let { close, value, setValue, setText } = this.props;
        return (
            <Fade duration={500}>
                <div className='editText-modal-overlay'>
                    <div className='editText-modal'>
                            <button className='editText-modal__top' onClick={close}>
                                <img src={icons.close} />
                            </button>
                        
                        <EditorToolbar />
                        <ReactQuill
                            theme='snow'
                            value={value}
                            modules={modules}
                            formats={formats}
                            className='editText-modal__editor'
                            onChange={(value) => setValue(value)}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    console.log(e);
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }}
                        />
                        <div className='editText-modal__bottom'>
                            <button onClick={setText}>Apply</button>
                        </div>
                    </div>
                </div>
            </Fade>
        )
    }
}
