import { getImgBlock } from "./imageBlock";
import { getTextBlock } from "./textBlock";
import { Column } from '@react-email/column';
import { Section } from '@react-email/section';

export const getListBlock = (img, text, params) => {
    var params_i = img.params;
    var params_t = text.params;
    return <table width="600px" cellspacing="0" align="center" cellpadding="0"  style={{
        maxWidth: "600px", width: '600px',
        borderTopLeftRadius: params.borderTopLeftRadius,
        borderBottomLeftRadius: params.borderBottomLeftRadius,
        borderTopRightRadius: params.borderTopRightRadius,
        borderBottomRightRadius: params.borderBottomRightRadius,
       
        
        marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor
    }}>
        <tr >
            <td style={{
                 borderTopLeftRadius: params.borderTopLeftRadius,
                 borderBottomLeftRadius: params.borderBottomLeftRadius,
                verticalAlign: 'top', paddingTop: params.paddingTop, paddingLeft: params.paddingLeft,
                paddingBottom: params.paddingBottom,
                width: params_i.width,
                backgroundColor: params_i.backgroundColor
            }}>{getImgBlock(params_i)}</td>
            <td style={{
                borderTopRightRadius: params.borderTopRightRadius,
                borderBottomRightRadius: params.borderBottomRightRadius,
                backgroundColor: params_t.backgroundColor, paddingTop: params.paddingTop, paddingRight: params.paddingRight,
                paddingBottom: params.paddingBottom,
            }}>{getTextBlock(params_t)}</td>
        </tr>

    </table>;
}