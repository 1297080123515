import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setArr } from '../../../actions/actions';
import { bindActionCreators } from 'redux';
import { SketchPicker } from 'react-color';
import { Fade } from 'react-reveal';
import { icons } from '../../../assets/icons';
import AttributeTooltip from '../../../components/AttributeTooltip/AttributeTooltip';
class FontBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fontFamily: 'Arial',
            fontSize: '',
            fontWeight: '',
            fontHeight: '',
            text: '',
            color: '#000000',
            align: '',
            show: false,
            openSelectFontFamily: false,
            openSelectFontSize: false,
            openSelectAlign: false,
        };
        this.selectFontFamilyRef = React.createRef();
        this.selectFontSizeRef = React.createRef();
        this.pickerRef = React.createRef();
    }

    componentDidMount = () => {
        this.getFonts();
        document.addEventListener('click', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside)
    }

    handleClickOutside = (e) => {
        if (!this.selectFontFamilyRef.current.contains(e.target)) {
            this.setState({ openSelectFontFamily: false })
        }
        if (!this.selectFontSizeRef.current.contains(e.target)) {
            this.setState({ openSelectFontSize: false })
        }
        if (!this.pickerRef.current.contains(e.target)) {
            this.setState({ show: false })
        }
    }

    getItemColor = (color) =>{
        if (color === undefined){
            return '#000000';
        }else if (color.length === 0){
            return '#000000';
        }
        return color;
        
    }

    getFonts = () => {
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                console.log(arr[i].params.item === undefined);
                var item = arr[i].params;
                this.setState({
                    fontFamily: item.fontFamily,
                    fontSize: item.fontSize,
                    fontWeight: item.fontWeight,
                    fontHeight: item.fontHeight,
                    text: item.text,
                    color: this.getItemColor(item.color),
                    align: item.align
                });
                return;
            }
        }
    }
    setFontFamily = (value) => {
        this.setState({ fontFamily: value });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.fontFamily = value;
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }
    setAlign = (value) => {
        this.setState({ align: value });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.align = value;
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }
    setFontSize = (value) => {
        this.setState({ fontSize: value });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.fontSize = value;
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }
    setfontWeight = (value) => {
        this.setState({ fontWeight: value });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.fontWeight = value;
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }
    setfontHeight = (value) => {
        this.setState({ fontHeight: value });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.fontHeight = value;
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }

    decimalToHex = (alpha) => alpha === 0 ? '00' : Math.round(255 * alpha).toString(16);

    setColor = (value) => {
        this.setState({ color: `${value?.hex}` });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.color = `${value?.hex}`;
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }

    resetColor = () => {
        this.setState({ color: '#000' });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.color = '#000';
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }

    render() {
       
        return (
            <div style={{ marginBottom: '16px' }}>
                <div>
                    <div className='attr-block'>
                        <p className='attr-label'>
                            Font
                            <AttributeTooltip />
                        </p>
                        <div className='custom-select'>
                            <p className='custom-select-input' ref={this.selectFontFamilyRef} style={{ width: '180px', borderColor: this.state.openSelectFontFamily ? '#34B9A0' : '', backgroundColor: this.state.openSelectFontFamily ? '#2f3c3a' : '' }} onClick={() => this.setState({ openSelectFontFamily: !this.state.openSelectFontFamily })}>{this.state.fontFamily} <img className='icon-transform' src={icons.next} style={{ float: 'right', marginTop: '2px', filter: 'brightness(1000%)', transform: this.state.openSelectFontFamily ? 'rotate(270deg)' : 'rotate(90deg)', scale: '0.8', marginLeft: '15px' }} /></p>
                            <Fade top duration={300} when={this.state.openSelectFontFamily}>
                                <div className='custom-select-options' style={{ width: '180px', display: this.state.openSelectFontFamily ? '' : 'none' }}>
                                    <p className='select-input' onClick={() => { this.setFontFamily("Arial"); this.setState({ openSelectFontFamily: false }); }}>Arial</p>
                                    <p className='select-input' onClick={() => { this.setFontFamily("Courier New"); this.setState({ openSelectFontFamily: false }); }}>Courier New</p>
                                    <p className='select-input' onClick={() => { this.setFontFamily("Georgia"); this.setState({ openSelectFontFamily: false }); }}>Georgia</p>
                                    <p className='select-input' onClick={() => { this.setFontFamily("Helvetica"); this.setState({ openSelectFontFamily: false }); }}>Helvetica</p>
                                    <p className='select-input' onClick={() => { this.setFontFamily("Lucida Sans"); this.setState({ openSelectFontFamily: false }); }}>Lucida Sans</p>
                                    <p className='select-input' onClick={() => { this.setFontFamily("Tahoma"); this.setState({ openSelectFontFamily: false }); }}>Tahoma</p>
                                    <p className='select-input' onClick={() => { this.setFontFamily("Times New Roman"); this.setState({ openSelectFontFamily: false }); }}>Times New Roman</p>
                                    <p className='select-input' onClick={() => { this.setFontFamily("Trebuchet MS"); this.setState({ openSelectFontFamily: false }); }}>Trebuchet MS</p>
                                </div>
                            </Fade>
                        </div>
                    </div>
                    <div className='attr-block'>
                        <p className='attr-label'>
                            Font Size
                            <AttributeTooltip />
                        </p>
                        <div className='custom-select'>
                            <p className='custom-select-input' ref={this.selectFontSizeRef} style={{ width: '90px', borderColor: this.state.openSelectFontSize ? '#34B9A0' : '', backgroundColor: this.state.openSelectFontSize ? '#2f3c3a' : '' }} onClick={() => this.setState({ openSelectFontSize: !this.state.openSelectFontSize })}>{this.state.fontSize} <img className='icon-transform' src={icons.next} style={{ float: 'right', marginTop: '2px', filter: 'brightness(1000%)', transform: this.state.openSelectFontSize ? 'rotate(270deg)' : 'rotate(90deg)', scale: '0.8', marginLeft: '15px' }} /></p>
                            <Fade top duration={300} when={this.state.openSelectFontSize}>
                                <div className='custom-select-options' style={{ width: '90px', display: this.state.openSelectFontSize ? '' : 'none' }}>
                                    <p className='select-input' onClick={() => { this.setFontSize("9px"); this.setState({ openSelectFontSize: false }); }}>9px</p>
                                    <p className='select-input' onClick={() => { this.setFontSize("12px"); this.setState({ openSelectFontSize: false }); }}>12px</p>
                                    <p className='select-input' onClick={() => { this.setFontSize("16px"); this.setState({ openSelectFontSize: false }); }}>16px</p>
                                    <p className='select-input' onClick={() => { this.setFontSize("18px"); this.setState({ openSelectFontSize: false }); }}>18px</p>
                                    <p className='select-input' onClick={() => { this.setFontSize("22px"); this.setState({ openSelectFontSize: false }); }}>22px</p>
                                    <p className='select-input' onClick={() => { this.setFontSize("24px"); this.setState({ openSelectFontSize: false }); }}>24px</p>
                                    <p className='select-input' onClick={() => { this.setFontSize("28px"); this.setState({ openSelectFontSize: false }); }}>28px</p>
                                    <p className='select-input' onClick={() => { this.setFontSize("32px"); this.setState({ openSelectFontSize: false }); }}>32px</p>
                                    <p className='select-input' onClick={() => { this.setFontSize("34px"); this.setState({ openSelectFontSize: false }); }}>34px</p>
                                    <p className='select-input' onClick={() => { this.setFontSize("36px"); this.setState({ openSelectFontSize: false }); }}>36px</p>
                                    <p className='select-input' onClick={() => { this.setFontSize("38px"); this.setState({ openSelectFontSize: false }); }}>38px</p>
                                    <p className='select-input' onClick={() => { this.setFontSize("40px"); this.setState({ openSelectFontSize: false }); }}>40px</p>
                                    <p className='select-input' onClick={() => { this.setFontSize("42px"); this.setState({ openSelectFontSize: false }); }}>42px</p>
                                    <p className='select-input' onClick={() => { this.setFontSize("48px"); this.setState({ openSelectFontSize: false }); }}>48px</p>
                                </div>
                            </Fade>
                        </div>
                    </div>
                    <div className='attr-block'>
                        <p className='attr-label'>
                            Text Align
                            <AttributeTooltip />
                        </p>
                        <button className={this.state.align === 'left' ? 'custom-align custom-align__checked' : 'custom-align'} onClick={() => { this.setAlign("left") }}>
                            <img src={icons.align_left} />
                        </button>
                        <button className={this.state.align === 'center' ? 'custom-align custom-align__checked' : 'custom-align'} onClick={() => { this.setAlign("center") }}>
                            <img src={icons.align_center} />
                        </button>
                        <button className={this.state.align === 'right' ? 'custom-align custom-align__checked' : 'custom-align'} onClick={() => { this.setAlign("right") }}>
                            <img src={icons.align_right} />
                        </button>
                    </div>
                    <div className='attr-block'>
                        <p className='attr-label'>
                            Font Weight
                            <AttributeTooltip />
                        </p>
                        <input className='attr-input' value={this.state.fontWeight} onInput={(e) => this.setfontWeight(e.target.value)} />
                    </div>
                    <div className='attr-block'>
                        <p className='attr-label'>
                            Line Height
                            <AttributeTooltip />
                        </p>
                        <input className='attr-input' value={this.state.fontHeight} onInput={(e) => this.setfontHeight(e.target.value)} />
                    </div>
                    <div ref={this.pickerRef} className='attr-block'>
                        <p className='attr-label'>
                            Text Color
                            <AttributeTooltip />
                        </p>
                      
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div onClick={() => this.setState({ show: !this.state.show })} style={{ marginRight: '12px', backgroundColor: this.state.color, borderRadius: '4px', width: '20px', height: '20px' }}></div>
                                <p style={{ color: 'white', fontSize: '12px', fontFamily: 'Montserrat' }}>{this.state.color}</p>
                            </div>
                            <button className='attr-reset' onClick={this.resetColor}>
                                <img src={icons.close_small} />
                            </button>
                        </div>
                        <Fade top duration={300} when={this.state.show}>
                            <div style={{ display: this.state.show ? '' : 'none', marginTop: '8px', position: '' }}>
                                <SketchPicker disableAlpha={true} color={this.state.color} onChangeComplete={(e) => this.setColor(e)} />
                            </div>
                        </Fade>
                    </div>

                </div>

            </div>
        );
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(FontBox);



/*


                    
                    

                </div>
                <div>
                    <p className='attr-label'>color <span style={{ cursor: 'pointer' }} onClick={() => this.setState({ show: !this.state.show })}>{this.state.show ? "▲" : "▼"}</span></p>
                    <div style={{ display: this.state.show ? '' : 'none' }}>
                        <SketchPicker color={this.state.color} onChangeComplete={(e) => this.setColor(e)} />
                    </div>
*/