import React, { Component } from 'react'
import { Fade } from 'react-reveal'

import './styles.css'

export default class DeleteCampaignModal extends Component {

    componentDidMount = () => {
        document.addEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        if (e.target.classList.contains('deleteCampaign-modal-overlay')) {
            this.props.close();
        }
    }

    render() {
        console.log(this.props)
        return (
            <Fade>
                <div className='deleteCampaign-modal-overlay'>
                    <div className='deleteCampaign-modal'>
                        <h3 className='deleteCampaign-modal-title'>Do you really want to delete this email?</h3>
                        <div className='deleteCampaign-modal-buttons'>
                            <button>Yes</button>
                            <button onClick={this.props.close}>No</button>
                        </div>
                    </div>
                </div>
            </Fade>
        )
    }
}
