import { render } from '@react-email/render';
import { Html } from '@react-email/html';
import { Body } from '@react-email/body';

import { getTextBlock } from '../pages/Constructor/renderBlocks/textBlock';
import { getImgBlock } from '../pages/Constructor/renderBlocks/imageBlock';
import { getImgButton } from '../pages/Constructor/renderBlocks/imageButton';
import { getListBlock } from '../pages/Constructor/renderBlocks/listBlock';
import { getLogoBlock } from '../pages/Constructor/renderBlocks/logoBlock';
import { getSocialBlock } from '../pages/Constructor/renderBlocks/socialBlock';
import { getTextLinkBlock } from '../pages/Constructor/renderBlocks/textLinkBlock';

export const getHtmlRaw = (array) => {
    var arr = [];
    var p_arr = array;

    for (var i = 0; i < p_arr.length; i++) {
        var item = p_arr[i];
        var params = item.params;
        if (params.color !== undefined) {
            params.color = getCorrectColor(params.color);
        }
        if (params.backgroundColor !== undefined) {
            params.backgroundColor = getCorrectColor(params.backgroundColor);
        }


        if (item.type === "text" && item.params.for === -99) {
            arr.push(<tr><td>{getTextBlock(params)}</td></tr>);
        } else if (item.type === "image" && item.params.for === -99) {
            arr.push(<tr><td>{getImgBlock(params)}</td></tr>);
        } else if (item.type === "link-img" && item.params.for === -99) {
            arr.push(<tr><td>{getImgButton(params)}</td></tr>);
        } else if (item.type === "list-block" && item.params.for === -99) {
            var __arr = array;
            var img = '';
            var text = '';
            for (var j = 0; j < __arr.length; j++) {
                if (__arr[j].params.for === item.id && __arr[j].type === "image") {
                    img = __arr[j];
                }
                if (__arr[j].params.for === item.id && __arr[j].type === "text") {
                    text = __arr[j];
                }
            }

            arr.push(<tr><td>{getListBlock(img, text, params)}</td></tr>);
        }
        else if (item.type === "logo-block" && item.params.for === -99) {
            var __arr = array;
            var img1 = '';
            var img2 = '';
            for (var j = 0; j < __arr.length; j++) {

                if (__arr[j].id === item.childrens[0]) {
                    img1 = __arr[j];
                }
                if (__arr[j].id === item.childrens[1]) {
                    img2 = __arr[j];
                }
            }
            arr.push(<tr><td>{getLogoBlock(img1, img2, params)}</td></tr>);

        } else if (item.type === "social-block" && item.params.for === -99) {
            var __arr = array;
            var block1 = '';
            var block2 = '';
            var block3 = '';
            var block4 = '';
            for (var j = 0; j < __arr.length; j++) {

                if (__arr[j].id === item.childrens[0]) {
                    block1 = __arr[j];
                }
                if (__arr[j].id === item.childrens[1]) {
                    block2 = __arr[j];
                }
                if (__arr[j].id === item.childrens[2]) {
                    block3 = __arr[j];
                }
                if (__arr[j].id === item.childrens[3]) {
                    block4 = __arr[j];
                }
            }
            arr.push(<tr><td>{getSocialBlock(block1, block2, block3, block4, params)}</td></tr>);
        } else if (item.type === "text-link" && item.params.for === -99) {
            arr.push(<tr><td>{getTextLinkBlock(params)}</td></tr>);
        }
    }
    var mainBlock = array.filter(x => x.id === '-122');
    var obj = {
        backgroundColor: '#ffffff',
        paddingTop: '0',
        paddingBottom: '0',
        paddingLeft: '0',
        paddingRight: '0'
    };
    if (mainBlock.length > 0) {
        obj = mainBlock[0].params;
    }
    const html = render(<Html><Body style={{ padding: '0', margin: '0' }}>
        <table width="600" cellspacing="0"
            style={{
                paddingTop: obj.paddingTop,
                paddingBottom: obj.paddingBottom,
                paddingLeft: obj.paddingLeft,
                paddingRight: obj.paddingRight,
                backgroundColor: obj.backgroundColor
            }}
            cellpadding="0" border="0" bgcolor={obj.backgroundColor} align="center">
            {arr.map(x => x)}
        </table>
    </Body></Html>, { pretty: true })
    return html
}
function getCorrectColor(raw) {
    if (raw !== '#ffffff') {
        if (raw.substr(raw.length - 2) === 'ff' && raw.length > 7) {
            return raw.substr(0, raw.length - 2);
        } else {
            return raw;
        }
    } else {
        return raw;
    }
}