import ImageBlock from "./imageBlock";
import ImageButton from "./imageButton";
import ListBlock from "./listBlock";
import LogoBlock from "./logoBlock";
import SocialBlock from "./socialBlock";
import TextBlock from "./textBlock";
import TextLinkBlock from "./textLinkBlock";

export  default function generateBlock(obj) {
    switch (obj.type) {
        case 'text':
            return <TextBlock type={obj.type} id={obj.id} />;
        case 'list-block':
            return <ListBlock id={obj.id} obj={obj.type} type={obj.type} />;
        case 'social-block':
            return <SocialBlock id={obj.id} block1={obj.childrens[0]} block2={obj.childrens[1]}
                block3={obj.childrens[2]} block4={obj.childrens[3]} type={obj.type} />;
        case 'logo-block':
            return <LogoBlock type={obj.type} id={obj.id} img1={obj.childrens[0]} img2={obj.childrens[1]} />;
        case 'image':
            return <ImageBlock type={obj.type} id={obj.id} />;
        case 'link-img':
            return <ImageButton type={obj.type} id={obj.id} />;
        case 'text-link':
            return <TextLinkBlock type={obj.type} id={obj.id} />
        default:
            return <></>;
    }
}