import { Text } from '@react-email/text';
import { Container } from '@react-email/container'
export const getTextBlock = (params) => {
    return <Container style={{
        marginTop: params.marginTop,
        marginLeft: params.marginLeft,
        align: params.align,
        paddingTop: params.paddingTop,
        paddingLeft: params.paddingLeft,
        paddingRight: params.paddingRight,
        maxWidth: '',
        backgroundColor: params.backgroundColor,
        textAlign: params.align,
        paddingBottom: params.paddingBottom,
        borderTopLeftRadius: params.borderTopLeftRadius,
        borderBottomLeftRadius: params.borderBottomLeftRadius,
        borderTopRightRadius: params.borderTopRightRadius,
        borderBottomRightRadius: params.borderBottomRightRadius,
        fontFamily: params.fontFamily,
        fontSize: params.fontSize,
        color: params.color,
        lineHeight: params.fontHeight,
        fontWeight: params.fontWeight,
        marginRight: params.marginRight,
        marginBottom: params.marginBottom,}}><Text dangerouslySetInnerHTML={{__html: params.text}} style={{
        color: params.color,
      
        textAlign: params.align,
        align: params.align,
        fontFamily: params.fontFamily,
        fontSize: params.fontSize,
        margin: '0',
        lineHeight: params.fontHeight,
        fontWeight: params.fontWeight
    }}>
        
    </Text></Container>; //TODO: params.add children generation
}