import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setArr } from '../../../actions/actions';
import { bindActionCreators } from 'redux';
import { icons } from '../../../assets/icons';
import AttributeTooltip from '../../../components/AttributeTooltip/AttributeTooltip';
import { determinatePercentage } from '../../../components/determinatePercentage';
class PaddingBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paddingTop: '0',
            paddingBottom: '0',
            paddingLeft: '0',
            paddingRight: '0',
            activeLink: false,
        };
    }
    componentDidMount = () => {
        this.getPadding();
    }

    toggleApplyLink = () => {
        this.setState({ activeLink: !this.state.activeLink })
    }

    getPadding = () => {
        console.log('padding');
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i].params;
                this.setState({
                    paddingTop: item.paddingTop,
                    paddingBottom: item.paddingBottom,
                    paddingLeft: item.paddingLeft,
                    paddingRight: item.paddingRight
                });
                return;
            }
        }
    }
    setPaddingTop = (value) => {
        let isHave = determinatePercentage(value);
        if (isHave) {
            return;
        } else {
            if (!this.state.activeLink) {
                this.setState({ paddingTop: value });
            } else {
                this.setState({
                    paddingTop: value,
                    paddingBottom: value,
                    paddingLeft: value,
                    paddingRight: value
                });
            }
            var id = this.props.for;
            var arr = Array.prototype.concat(this.props.arr);
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === id) {
                    var item = arr[i];
                    if (!this.state.activeLink) {
                        item.params.paddingTop = value;
                    } else {
                        item.params.paddingTop = value;
                        item.params.paddingBottom = value;
                        item.params.paddingLeft = value;
                        item.params.paddingRight = value;
                    }
                    arr[i] = item;
                    break;
                }
            }
            this.props.setArr(arr);
        }
    }
    setPaddingLeft = (value) => {
        let isHave = determinatePercentage(value);
        if (isHave) {
            return;
        } else {
            if (!this.state.activeLink) {
                this.setState({ paddingLeft: value });
            } else {
                this.setState({
                    paddingTop: value,
                    paddingBottom: value,
                    paddingLeft: value,
                    paddingRight: value
                });
            }
            var id = this.props.for;
            var arr = Array.prototype.concat(this.props.arr);
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === id) {
                    var item = arr[i];
                    if (!this.state.activeLink) {
                        item.params.paddingLeft = value;
                    } else {
                        item.params.paddingTop = value;
                        item.params.paddingBottom = value;
                        item.params.paddingLeft = value;
                        item.params.paddingRight = value;
                    }
                    arr[i] = item;
                    break;
                }
            }
            this.props.setArr(arr);
        }
    }
    setPaddingRight = (value) => {
        let isHave = determinatePercentage(value);
        if (isHave) {
            return;
        } else {
            if (!this.state.activeLink) {
                this.setState({ paddingRight: value });
            } else {
                this.setState({
                    paddingTop: value,
                    paddingBottom: value,
                    paddingLeft: value,
                    paddingRight: value
                });
            }
            var id = this.props.for;
            var arr = Array.prototype.concat(this.props.arr);
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === id) {
                    var item = arr[i];
                    if (!this.state.activeLink) {
                        item.params.paddingRight = value;
                    } else {
                        item.params.paddingTop = value;
                        item.params.paddingBottom = value;
                        item.params.paddingLeft = value;
                        item.params.paddingRight = value;
                    }
                    arr[i] = item;
                    break;
                }
            }
            this.props.setArr(arr);
        }
    }
    setPaddingBottom = (value) => {
        let isHave = determinatePercentage(value);
        if (isHave) {
            return;
        } else {
            if (!this.state.activeLink) {
                this.setState({ paddingBottom: value });
            } else {
                this.setState({
                    paddingTop: value,
                    paddingBottom: value,
                    paddingLeft: value,
                    paddingRight: value
                });
            }
            var id = this.props.for;
            var arr = Array.prototype.concat(this.props.arr);
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === id) {
                    var item = arr[i];
                    if (!this.state.activeLink) {
                        item.params.paddingBottom = value;
                    } else {
                        item.params.paddingTop = value;
                        item.params.paddingBottom = value;
                        item.params.paddingLeft = value;
                        item.params.paddingRight = value;
                    }
                    arr[i] = item;
                    break;
                }
            }
            this.props.setArr(arr);
        }
    }

    render() {
        return (
            <div>
                <div className='attr-block'>
                    <p className='attr-label'>
                        Padding
                        <AttributeTooltip/>
                    </p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '24px' }}>
                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginRight: '24px' }}>
                                    <img src={icons.padding_margin} style={{ marginRight: '8px' }} />
                                    <input className='attr-input' style={{ width: '64px' }} value={this.state.paddingLeft} onInput={(e) => this.setPaddingLeft(e.target.value)} />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={icons.padding_margin} style={{ transform: 'rotate(90deg)', marginRight: '8px' }} />
                                    <input className='attr-input' style={{ width: '64px' }} value={this.state.paddingTop} onInput={(e) => this.setPaddingTop(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginRight: '24px' }}>
                                    <img src={icons.padding_margin} style={{ transform: 'rotate(180deg)', marginRight: '8px' }} />
                                    <input className='attr-input' style={{ width: '64px' }} value={this.state.paddingRight} onInput={(e) => this.setPaddingRight(e.target.value)} />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={icons.padding_margin} style={{ transform: 'rotate(270deg)', marginRight: '8px' }} />
                                    <input className='attr-input' style={{ width: '64px' }} value={this.state.paddingBottom} onInput={(e) => this.setPaddingBottom(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <button className={this.state.activeLink ? 'attr-link attr-link__active' : 'attr-link'} onClick={this.toggleApplyLink}>
                            {this.state.activeLink ? (
                                <img className='attr-link__img' src={icons.link_active}/>
                            ) : (
                                <img className='attr-link__img' src={icons.link}/>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(PaddingBox);