import React, { Component } from 'react';
import { connect } from 'react-redux';
import { putElm, setArr } from '../../../actions/actions';
import { Text } from '@react-email/text';
import Modal from 'react-modal';
import { bindActionCreators } from 'redux';
import { SketchPicker } from 'react-color';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Menu from '../../../components/Menu';
import EditTextModal from '../../../components/EditTextModal/EditTextModal';

function hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
    }
}

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        width: '700px',
        height: '550px',
        right: 'auto',
        zIndex: 2,
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white'
    },
};
var Size = Quill.import("attributors/style/size");
Size.whitelist = [
    "9px",
    "10px",
    "11px",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "22px",
    "24px",
    "26px",
    "28px",
];
Quill.register(Size, true);
const quill_models = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike'],
            ['link'],
            [{
                size: [
                    "9px",
                    "10px",
                    "11px",
                    "12px",
                    "14px",
                    "16px",
                    "18px",
                    "20px",
                    "22px",
                    "24px",
                    "26px",
                    "28px",
                ],
            },
            ],
            [{ 'color': ["#FFFFFF", "#C0C0C0", "#808080", "#000000", "#FF0000", "#800000", "#FFFF00", "#808000", "#00FF00", "#008000", "#00FFFF", "#008080", "#0000FF", "#000080", "#FF00FF", "#800080", 'custom-color'] }]
        ],
        handlers: {
            'color': function (value) {
                if (value == 'custom-color') value = window.prompt('Enter Hex Color Code');
                if (value[0] === '#') {
                    let rgba = hexToRgbA(value);
                    this.quill.format('color', rgba);
                    return;
                } else {
                    this.quill.format('color', value);
                    return;
                }
            },
            'size': function (value) {
                this.quill.format('size', value);
                return;
            },
            'link': function (value) {
                if (value) {
                    var href = prompt('Enter the URL');
                    if (href.indexOf("http://") === -1 && href.indexOf("https://") === -1) {
                        href = "https://" + href;
                    }
                    this.quill.format('link', href);
                } else {
                    this.quill.format('link', false);
                }
            }
        }
    }

};
class TextBlock extends Component {

    constructor(props) {
        super();

        // const editor = Quill.getEditor();
        this.state = {
            modalIsOpen: false,
            dgdText: '',
            colorPicker: '#333',
            displayColor: false,
            id: -99,
        };
    }
    getBackground = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.backgroundColor;
            }
        }
    }
    getFontFamily = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.fontFamily;
            }
        }
    }
    getFontSize = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.fontSize;
            }
        }
    }
    getFontHeight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.fontHeight;
            }
        }
    }
    getFontWeight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.fontWeight;
            }
        }
    }
    getMarginTop = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginTop;
            }
        }
    }
    getMarginLeft = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginLeft;
            }
        }
    }
    getMarginRight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginRight;
            }
        }
    }
    getMarginBottom = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginBottom;
            }
        }
    }
    getPaddingTop = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingTop;
            }
        }
    }
    getPaddingLeft = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingLeft;
            }
        }
    }
    getPaddingRight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingRight;
            }
        }
    }
    getPaddingBottom = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingBottom;
            }
        }
    }
    getText = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {

                return arr[i].params.text;
            }
        }
    }
    getColor = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.color;
            }
        }
    }
    getAlign = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.align;
            }
        }
    }
    getDgDtext = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {

                this.setState({ dgdText: arr[i].params.text });
            }
        }
    }
    componentDidMount = () => {
        this.getDgDtext();
        this.getId();
        document.querySelectorAll('#moved-block')[0].classList.add('menu-moveBottom')
        // document.querySelectorAll('#text-block')[document.querySelectorAll('#text-block').length - 1].classList.add('newClassName2')
    }

    componentWillUnmount = () => {
        document.querySelectorAll('#moved-block')[0].classList.remove('menu-moveBottom')
    }

    getId = () => {
        // var res = this.props.arr.filter(item=> item.block);
        console.log(this._reactInternalInstance);
    }

    setModal = () => {
        var open = !this.state.modalIsOpen;
        this.setState({ modalIsOpen: open });
    }

    setText = () => {

        var id = this.props.id;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.text = this.state.dgdText.replace('<p>', '').replace('</p>', '');
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
        this.setModal();
    }

    checkIfCanInteract = () => {
        return this.props.arr.filter(item => item.id === this.props.id)[0].params.for === -99;
    }

    editBlock = () => {
        this.props.putElm({ id: this.props.id, type: 'text' })
    }

    deleteBlock = () => {
        const deletedBlock = this.props.arr.filter(item => item.id === this.props.id);
        if (deletedBlock[0].params.for !== -99) return;
        this.props.setArr(this.props.arr.filter(item => item.id !== this.props.id));
    }

    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    copyBlock = () => {
        let id = this.uuidv4();
        const block = this.props.arr.filter(item => item.id === this.props.id);
        const modifiedBlock = structuredClone(block.map(item => {
            return { ...item, id: id }
        }))
        this.props.arr.push(modifiedBlock[0]);
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);
    }

    elemMove = (direction) => {
        let arr = this.props.arr.filter(elem => elem.params.for === -99);
        let arrWithout99 = this.props.arr.filter(elem => elem.params.for !== -99);
        let selectedElemIndex = 0;
        let selectedElem = arr.find((elem, index) => {
            selectedElemIndex = index;
            return elem.id === this.props.id
        });
        let res = [];
        if (direction === 'up') {
            if (selectedElemIndex === 0) return;
            const arrCopy = [...arr];
            let element = arrCopy[selectedElemIndex];
            arrCopy.splice(selectedElemIndex, 1);
            arrCopy.splice(selectedElemIndex - 1, 0, element);
            res = arrCopy;
        }
        if (direction === 'down') {
            if (selectedElemIndex === this.props.arr.length - 1) return;
            const arrCopy = [...arr];
            let element = arrCopy[selectedElemIndex];
            arrCopy.splice(selectedElemIndex, 1);
            arrCopy.splice(selectedElemIndex + 1, 0, element);
            res = arrCopy;
        }
        this.props.setArr(res.concat(arrWithout99));
    }

    setDgdText = (value) => {
        this.setState({ dgdText: value })
    }
    getborderTopLeftRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.borderTopLeftRadius;
            }
        }
    }
    getborderBottomLeftRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.borderBottomLeftRadius;
            }
        }
    }
    getborderTopRightRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.borderTopRightRadius;
            }
        }
    }
    getborderBottomRightRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.borderBottomRightRadius;
            }
        }
    }
    render() {
        return <div id='moved-block' className='highlighted__childElem'>

            {/* <Modal style={modalStyle} isOpen={this.state.modalIsOpen}>
                <div style={{ textAlign: 'right' }}>
                    <img style={{ cursor: 'pointer' }} onClick={() => this.setModal()} src=" data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACmSURBVHgB7VSJCYAwDEydpCu4gY7iZI5SN+gK3aQm0kLBNA1CEbQHEshzF84SgIHPw3BJ5701Ju4xmm2d5yARtHonVhUHMCwYHRGATO5S7871sAK0DYaAn62JFORUC2nmviy0t8sEa7ZAqqkFakTXkJK8KcCJpLSKXCXAiICWnDBBZ7xrUdef3PWZagi0ItKpEAcplyyjmn1yKo6Wv4XIUTsVAz/ACRI/tRSYO0myAAAAAElFTkSuQmCC" />
                </div>
                <div>
                    <div style={{ position: 'absolute', marginTop: '150px', display: this.state.displayColor ? '' : 'none' }}>
                        <SketchPicker quill-editor-toolbar onChangeComplete={(e) => this.setState({ colorPicker: e })} color={this.state.colorPicker} />
                        <p onClick={() => this.setState({ displayColor: false })} Style="background: #34B9A0;border-radius: 4px;padding: 8px 16px;cursor: pointer;width: auto;text-align: center; ">Готово</p>
                    </div>
                    <ReactQuill onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            console.log(e);
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }} value={this.state.dgdText} onChange={(value) => this.setState({ dgdText: value })}
                        modules={quill_models} theme='snow' />

                </div>
                <div style={{ textAlign: 'right' }}>
                    <p onClick={() => this.setText()} Style="font-family: 'Montserrat';background: #34B9A0;border-radius: 4px;padding: 8px 16px;cursor: pointer;width: auto;text-align: center;  ">Зберегти</p>
                </div>
            </Modal> */}

            {this.state.modalIsOpen &&
                <EditTextModal
                    value={this.state.dgdText}
                    setValue={this.setDgdText}
                    close={this.setModal}
                    setText={this.setText}
                />
            }

            <Text className='highlighted__childElem' data-content='Text' onDoubleClick={(e) => this.setModal()} onClick={() => this.props.putElm({ id: this.props.id, type: "text" })} style={{
                display: this.child ? 'inline' : '',
                border: this.props.selectedElm == this.props.id ? '2px solid #34b9a0' : '',
                backgroundColor: this.getBackground(), paddingTop: this.getPaddingTop(), paddingBottom: this.getPaddingBottom(),
                paddingLeft: this.getPaddingLeft(), paddingRight: this.getPaddingRight(),
                borderTopLeftRadius: this.getborderTopLeftRadius(),
                borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                borderTopRightRadius: this.getborderTopRightRadius(),
                borderBottomRightRadius: this.getborderBottomRightRadius(),
                marginTop: this.getMarginTop(), marginBottom: this.getMarginBottom(), marginLeft: this.getMarginLeft(),
                marginRight: this.getMarginRight(), textAlign: this.getAlign(), color: this.getColor(),
                fontSize: this.getFontSize(), fontFamily: this.getFontFamily(), fontWeight: this.getFontWeight(),
                lineHeight: this.getFontHeight()
            }} dangerouslySetInnerHTML={{ __html: this.getText() }}>

                {/*this.children.map(x => )*/}
            </Text>
            <Menu
                edit={this.editBlock}
                canInteract={this.checkIfCanInteract()}
                delete={this.deleteBlock}
                copy={this.copyBlock}
                moveUp={() => this.elemMove('up')}
                moveDown={() => this.elemMove('down')}
            />
        </div>
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ putElm: putElm, setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr,
        selectedElm: state.selectedElm
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(TextBlock);