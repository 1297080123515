import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { icons } from '../../../assets/icons';
import { setArr, setEmailId } from '../../../actions/actions';
import { getHtmlRaw } from '../../getHtmlRaw'
import DeleteCampaignModal from '../../DeleteCampaignModal/DeleteCampaignModal';

import './styles.css'

class Creative extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      preview: [],
      saved: [
        /* { id: 1, title: 'Mailer-Seg2-v01' },
         { id: 2, title: 'Mailer-Seg2-v02' },
         { id: 3, title: 'Mailer-Seg2-v03' },
         { id: 4, title: 'Mailer-Seg2-v04' },
         { id: 5, title: 'Mailer-Seg2-v05' },*/
      ],
      selectedSavedItem: 0,
      openedMenu: false,
      idOpenedMenuItem: 0,
      openedDeleteModal: false
    }
  }

  componentDidMount = () => {
    var data = this.props.emails_data;
    this.setState({ data: data });
    document.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside = (e) => {
    if (e.target.classList.contains('detailsCreative-saved__list-item__menu-wrapper')) {
      this.setState({ idOpenedMenuItem: 0, openedMenu: false, openedDeleteModal: false })
    }
  }

  selectSavedItem = (id) => {
    this.setState({ selectedSavedItem: id });
    this.setState({ idOpenedMenuItem: 0 });
    this.setState({ openedMenu: false });
    this.setState({ openedDeleteModal: false })
    var _data = this.state.data.filter(item => item.id === id)[0].data;
    this.props.setArr(JSON.parse(_data));
    this.setState({ preview: JSON.parse(_data) });
  }
  selectEmail = (id) => {
    var elem = this.state.data.filter(item => item.id === id)[0];
    this.props.setArr(JSON.parse(elem.data));
    this.props.setEmailId(elem.id);
    this.props.setCurrentPage('constructor', elem);
  }
  toggleMenu = (id) => {
    if (id === this.state.idOpenedMenuItem) {
      this.setState({ idOpenedMenuItem: 0 })
      this.setState({ openedMenu: false })
    }
    if (id !== this.state.idOpenedMenuItem) {
      this.setState({ idOpenedMenuItem: id })
      this.setState({ openedMenu: true })
    }
  }
  // removeEmail = (id) => {
  //   var json = {
  //       token: this.props.token,
  //       id: id
  //   };
  //   fetch('RemoveEmail', {
  //       method: "POST",
  //       body: JSON.stringify(json),
  //       headers: {
  //           'Accept': 'application/json',
  //           'Content-Type': 'application/json'
  //       },
  //   }).then(x=> this.props.setCurrentPage('campaign_details'));
  // }

  openDeleteModal = () => {
    this.setState({ openedDeleteModal: true })
  }
  closeDeleteModal = () => {
    this.setState({ openedDeleteModal: false })
  }

  render() {
    return (
      <div style={{ marginBottom: '50px' }}>
        <div className='detailsCreative-content'>
          <div style={{ width: '360px' }}>
            <div className='detailsCreative-saved'>
              <h3 className='detailsCreative-saved__title'>Name</h3>
              <ul className='detailsCreative-saved__list'>
                {this.state.data.map(item => (
                  <>
                    <li className='detailsCreative-saved__list-item' key={item.id}>
                      <div className='detailsCreative-saved__list-item__title'>
                        <span className='detailsCreative-saved__list-item__title-text' style={{color: this.state.selectedSavedItem === item.id && '#34B9A0'}}>{item.name}</span>
                      </div>
                      <button onClick={() => this.toggleMenu(item.id)} className='detailsCreative-saved__list-item__button'>
                        <img src={icons.show_more} />
                      </button>
                      {this.state.openedMenu && this.state.idOpenedMenuItem === item.id && (
                        <div className='detailsCreative-saved__list-item__menu'>
                          <div className='detailsCreative-saved__list-item__menu-wrapper'></div>
                          <button className='detailsCreative-saved__list-item__menu-button' onClick={() => this.selectSavedItem(item.id)}>Open</button>
                          <button onClick={this.openDeleteModal} className='detailsCreative-saved__list-item__menu-button'>Delete</button>
                          <button className='detailsCreative-saved__list-item__menu-button' onClick={() => this.selectEmail(item.id)}>Edit</button>
                        </div>
                      )}
                    </li>
                    {this.state.openedDeleteModal && 
                     this.state.idOpenedMenuItem === item.id && 
                     <DeleteCampaignModal 
                      close={this.closeDeleteModal} 
                      id={item.id} 
                      token={this.props.token} 
                      setCurrentPage={this.props.setCurrentPage}
                    />}
                  </>
                ))}
              </ul>
            </div>
          </div>
          <iframe
            className='detailsCreative-content__preview'
            srcDoc={getHtmlRaw(this.state.preview)}
            style={{ background: this.state.preview.length !== 0 ? 'white' : '' }}
          />
        </div>
      </div>
    )
  }
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ setArr: setArr, setEmailId: setEmailId }, dispatch);
}
const mapStateToProps = state => {
  return {
    emails_data: state.emails_data,
    token: state.token

  }
}

export default connect(mapStateToProps, matchDispatchToProps)(Creative);