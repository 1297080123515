import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setArr } from '../../../actions/actions';
import { bindActionCreators } from 'redux';
import { SketchPicker } from 'react-color';
import { Fade } from 'react-reveal';
import { icons } from '../../../assets/icons';
import AttributeTooltip from '../../../components/AttributeTooltip/AttributeTooltip';

class BackgroundBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: '',
            show: false
        };
        this.pickerRef = React.createRef();
    }
    
    componentDidMount = () => {
        this.getBackground();

        document.addEventListener('click', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside)
    }

    handleClickOutside = (e) => {
        if (e.target !== document.getElementById('color_button')){
            var childs = Array.prototype.slice.call(document.getElementsByClassName('sketch-picker')[0].getElementsByTagName("*"));
            var found = false;
            for(var i = 0; i < childs.length;i++){
                if (childs[i] === e.target){
                    found = true;
                }
            }
            if (!found){
                this.setState({show: false});
            }
        }
    }

    getBackground = () => {

        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {

                var item = arr[i];
                this.setState({ backgroundColor: item.params.backgroundColor === '' ? '#fff' : item.params.backgroundColor });
                return;
            }
        }
    }

    decimalToHex = (alpha) => alpha === 0 ? '00' : Math.round(255 * alpha).toString(16);

    setBackground = (value) => {
        this.setState({ backgroundColor: `${value?.hex}` });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.backgroundColor = `${value?.hex}`;
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }

    resetBackground = () => {
        this.setState({ backgroundColor: '#fff' });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.backgroundColor = '#fff';
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }

    render() {
        return (
            <div ref={this.pickerRef} className='attr-block'>
                <p className='attr-label'>
                    Background
                    <AttributeTooltip />
                </p>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div id="color_button" onClick={() => this.setState({ show: !this.state.show })} style={{ marginRight: '12px', backgroundColor: this.state.backgroundColor, borderRadius: '4px', width: '20px', height: '20px' }}></div>
                        <p style={{ color: 'white', fontSize: '12px', fontFamily: 'Montserrat' }}>{this.state.backgroundColor}</p>
                    </div>
                    <button className='attr-reset' onClick={this.resetBackground}>
                        <img src={icons.close_small} />
                    </button>
                </div>

                <Fade top duration={300} when={this.state.show}>
                    <div style={{ display: this.state.show ? '' : 'none', marginTop: '8px', position: '' }}>
                        <SketchPicker disableAlpha={true} color={this.state.backgroundColor} onChangeComplete={(e) => this.setBackground(e)} />
                    </div>
                </Fade>
            </div>
        );
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(BackgroundBox);

{/*    <p>backgroundColor</p>
                <input value={this.state.backgroundColor} onInput={(e) => this.setBackground(e.target.value)} /> */}