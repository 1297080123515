export const newEmail = (campaignId, token, setArr, setEmailId) => {
    var json = {
        campaignId: campaignId,
        token: token,
        data: '[]'
    };
    fetch('UploadEmail', {
        method: "POST",
        body: JSON.stringify(json),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(x => x.json()).then(x => {
        setArr([]);
        setEmailId(x.emailId);
    });
}